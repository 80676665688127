.modal-body-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0px;
}

.description-container {
  font-family: 'Nunito-SemiBold';
  min-height: 50px;
  margin-bottom: 30px;
}

.button-container {
  display: flex;
  justify-content: center;
}

.in-progress-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error-container {
  display: flex;
  margin: 1em;
  justify-content: center;

  p {
    color: red;
    font-size: 13px;
    font-weight: bold;
  }
}
