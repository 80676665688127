.Button {
  &:focus {
    outline: 0;
  }
}

.DropDown {
  &:focus {
    outline: 0;
  }
}

// Style the scrollbar
.DropDown::-webkit-scrollbar {
  width: 6px;
}
.DropDown::-webkit-scrollbar-track {
  background-color: white; //#f9f9f9; //#fbfbfb; //#f5f5f5;
}

.DropdownItem {
  &:focus {
    outline: 0;
  }
}
