// Input

.ui.input {
  width: 100%;
}

.ui.input > input {
  border-radius: 20px;
  height: 36px;
  &:focus {
    border-color: #204695;
  }
}

.ui.labeled.input > .label:not(.corner) {
  height: 36px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.ui[class*='left icon'].input > input {
  padding-left: 49px !important;
  padding-right: 1em !important;
}

// Button

.ui.primary.button {
  background-color: #204695;
  font-family: Nunito-SemiBold;
  font-size: 14px;
  border-radius: 20px;
  height: 36px;
  min-width: 140px;

  &:hover {
    background-color: #204695;
  }
  &:focus {
    background-color: #204695;
    color: white;
    border: none;
  }
}

.ui.secondary.button {
  background-color: white;
  color: #204695;
  font-family: Nunito-SemiBold;
  font-size: 14px;
  border-radius: 20px;
  height: 36px;
  min-width: 140px;
  border: 1px solid #204695;

  &:hover {
    background-color: white;
    border: 1px solid #204695;
    color: #204695;
  }
  &:focus {
    background-color: white;
    border: 1px solid #204695;
    color: #204695;
  }
}

.ui.basic.striped.table tbody tr:nth-child(2n) {
  background-color: #ffffff !important;
  &:hover {
    background-color: #ebecef !important;
  }
}

.ui.basic.striped.table tbody tr:nth-child(2n - 1) {
  background-color: #f4f5f9 !important;
  &:hover {
    background-color: #ebecef !important;
  }
}

/** Button **/

@keyframes buttonLoadingSpinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

.button--loading {
  position: relative;
  .button__text {
    visibility: hidden;
    opacity: 0;
  }
  &::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: #ffffff;
    border-radius: 50%;
    animation: buttonLoadingSpinner 1s ease infinite;
  }
}
