.loading {
  -webkit-animation: loading 1s infinite; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: loading 1s infinite; /* Firefox < 16 */
  -ms-animation: loading 1s infinite; /* Internet Explorer */
  -o-animation: loading 1s infinite; /* Opera < 12.1 */
  animation: loading 1s infinite;
}

.control-center-view-container {
  padding: 36px 4%;

  .flex, .flex-2 {
    display: flex;

    &.justify-content {
      justify-content: space-between;
      width: 100%;
    }
  }

  .input-container {
    width: 70%;
    left: 30%;
    position: relative;

    .search {
      border: none;
      border-radius: 22px;
    }

    .search-input {
      background-color: transparent;
      border: none;
      border-radius: 22px;
      height: 44px;

      &::-webkit-input-placeholder {
        /* Chrome */
        color: white !important;
        opacity: 0.5 !important;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: white !important;
        opacity: 0.5 !important;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: white !important;
        opacity: 0.5 !important;
      }
      &:-moz-placeholder {
        /* Firefox 4 - 18 */
        color: white !important;
        opacity: 0.5 !important;
      }
    }

    .search-icon {
      position: absolute;
      left: 7px;
      top: 7px;
    }
  }

  .error {
    color: #FF4A4A;
    margin: 12px 0;
  }
}

.breadcrumb-container {
  display: flex;
  font-size: 24px;
  margin-bottom: 36px;

  > i {
    line-height: 22px;
  }

  .breadcrumb-item {
    cursor: pointer;
    margin: 0 6px;
    background: none;
    border: none;
    line-height: 22px;

    &:first-child {
      margin-left: 0;
      margin-right: 6px;
    }
  }
}

.dark-modal {
  h1, h2, h3, h4, h5, h6, p {
    color: white;
  }

  > * {
    border-color: rgba(255,255,255,.4) !important;
  }
}

.progress-modal {
  &:not(.complete) {
    background-color: transparent !important;
    box-shadow: none !important
  }

  > * {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ol, p {
    color: white;
  }
}

.confirmation-modal {
  min-height: unset !important;
  color: white;
}


.dark-form {
  .datepicker .field {
    width: 100%;
  }

  input, select, .selection.dropdown {
    background-color: transparent !important;
    color: white !important;
    border-width: 2px !important;
    border-color: rgba(255,255,255,.4) !important;
    height: 40px !important;
  }

  i {
    color: white !important;
  }

  .clndr-calendars-segment {
    background-color: rgb(12, 12, 39) !important;
    border-color: rgba(255,255,255,.4) !important;
    color: white;

    .clndr-days {
      color: black;
    }

    .icon.button {
      box-shadow: 0 0 0 1px rgba(255,255,255,.4) inset;
      &:hover{
        i {
          color: black !important;
        }
      }
    }
  }

  .selection.dropdown {
    .text {
      color: white !important;
    }

    .menu {
      background-color: rgb(12, 12, 39) !important;
      border-width: 2px !important;
      border-color: rgba(255,255,255,.4) !important;
      border-top: none !important;
      margin-left: -2px;
      margin-top: 2px;
      width: calc(100% + 4px);

      .item {
        border-top: none !important;

        &:hover {
          background-color: rgba(255,255,255,.2);
        }
      }
    }
  }

  .checkbox {
    label {
      &:before {
        background-color: transparent !important;
        border-color: rgba(255,255,255,.4) !important;
      }
    }

    &.checked label{
      &:after {
        color: white !important;
      }
    }
  }
}

.fade {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

@keyframes loading {
  0% { opacity: 0.5; }
  50% { opacity: 0.2; }
  100% { opacity: 0.5; }
}

/* Firefox < 16 */
@-moz-keyframes loading {
  0% { opacity: 0.5; }
  50% { opacity: 0.2; }
  100% { opacity: 0.5; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes loading {
  0% { opacity: 0.5; }
  50% { opacity: 0.2; }
  100% { opacity: 0.5; }
}

/* Internet Explorer */
@-ms-keyframes loading {
  0% { opacity: 0.5; }
  50% { opacity: 0.2; }
  100% { opacity: 0.5; }
}

/* Opera < 12.1 */
@-o-keyframes loading {
  0% { opacity: 0.5; }
  50% { opacity: 0.2; }
  100% { opacity: 0.5; }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}