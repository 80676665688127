.customer-container {

  .detail-header {
    display: flex;
    justify-content: space-between;
  }

  .totals-table {
    max-width: 450px;
  }

  .paginator-container {
    overflow: hidden;
    margin-bottom: 1em;
  }

  .plan-detail {
    color: white;

    span {
      font-size: 24px;
      padding-right: 1rem;
    }
  }

  .list-container {
    padding: 36px 0;
    overflow: hidden;

    > h2 {
      color: white;
    }

    div > h2 {
      color: white;
    }

    &.flex-2 {
      > * {
        margin-top: 0px;
      }
    }
  }

  .formulary-link, .submit-btn {
    width: 134px;
    height: 37px;
    border-radius: 21.5px;
    margin-top: 10px;
    margin-bottom: 10px;
    z-index: 1;

    &.big {
      width: 150px;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
  }

  .search-container {
    .upload-container {
      float: right;

      span {
        color: white;
      }
    }

    .upload-btn {
      background-color: transparent;
      border: none;
      margin-top: 6px;
      cursor: pointer;
    }

    .hint-message {
      text-align: center;
      color: white;
      padding: 20px 0;
    }
  }
}
