.loadingScreenContainer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 0;
  background-color: white;
  z-index: 102;
}

.loadingScreenContainer.relativeContainer {
  position: relative;
  height: 100%;
  width: 100%;
}
