.Button {
  &:focus {
    outline: 0;
  }
}

.DropDown {
  &:focus {
    outline: 0;
  }
}

.DropdownItem {
  &:focus {
    outline: 0;
  }
  &:hover {
    background-color: #f4f5f9;
  }
}
