.users-container {
  .list-container {
    padding: 36px 0;
    overflow: hidden;
  }

  .user-detail {
    color: white;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
  }

  .submit-btn {
    width: 134px;
    height: 37px;
    border-radius: 21.5px;
    margin-left: 10px;
    margin-bottom: 10px;
    z-index: 1;

    &.large {
      width: auto;
    }

    &:disabled {
      cursor: not-allowed !important;
    }
  }

  .search-container {
    display: grid;
    grid-template-columns: 250px auto;
    padding: 12px;

    &.active {
      background-color: rgba(255,255,255,0.1);
    }

    label {
      color: white;
      font-weight: bold;

      & + div {
        margin-top: 3px;
      }
    }

    .search-bar {
      padding: 20px 0px;
    }

    .upload-container {
      float: right;

      span {
        color: white;
      }
    }

    .upload-btn {
      background-color: transparent;
      border: none;
      margin-top: 6px;
      cursor: pointer;
    }

    .hint-message {
      text-align: center;
      color: white;
      padding: 20px 0;
    }
  }
}
