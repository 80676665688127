.admin-configuration-container {
  .config-form-container {
    display: flex;
    width: 50%;
    margin: 1em 0;

    form {
      width: 100%;
    }
  }
  h1, h2 {
    color: white;
  }

  .error-container {
    margin: 1.5em;
    p {

    }
  }
}
