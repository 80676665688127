.Section {
  transition-timing-function: ease-out;
  transition-property: color;
  transition-duration: 0.1s;

  &:focus {
    outline: 0;
  }
}

.ToggleSwitch {
  transition-timing-function: ease-out;
  transition-property: left;
  transition-duration: 0.1s;
}
