@import 'https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/semantic.min.css';

body {
  margin: 0;
  /* background-color: red; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Default styles for text tags */
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
  color: #3e4042;
}

h1 {
  font-family: Nunito-Bold;
  font-size: 25px;
  line-height: 37px;
}
h2 {
  font-family: Nunito-Medium;
  font-size: 20px;
  line-height: 31px;
}
h3 {
  font-family: Nunito-SemiBold;
  font-size: 18px;
  line-height: 28px;
}
h4 {
  font-family: Nunito-SemiBold;
  font-size: 16px;
  line-height: 27px;
}
h5 {
  font-family: Nunito-Regular;
  font-size: 15px;
  line-height: 23px;
}
h6 {
  font-family: Nunito-Regular;
  font-size: 14px;
  line-height: 23px;
}
p {
  font-family: Nunito-Light;
  font-size: 14px;
  line-height: 23px;
}

@font-face {
  font-family: Nunito-Black;
  src: url(./Assets/Fonts/Nunito-Black.ttf);
}
@font-face {
  font-family: Nunito-Bold;
  src: url(./Assets/Fonts/Nunito-Bold.ttf);
}
@font-face {
  font-family: Nunito-ExtraBold;
  src: url(./Assets/Fonts/Nunito-ExtraBold.ttf);
}
@font-face {
  font-family: Nunito-ExtraLight;
  src: url(./Assets/Fonts/Nunito-ExtraLight.ttf);
}
@font-face {
  font-family: Nunito-Light;
  src: url(./Assets/Fonts/Nunito-Light.ttf);
}
@font-face {
  font-family: Nunito-Medium;
  src: url(./Assets/Fonts/Nunito-Medium.ttf);
}
@font-face {
  font-family: Nunito-Regular;
  src: url(./Assets/Fonts/Nunito-Regular.ttf);
}
@font-face {
  font-family: Nunito-SemiBold;
  src: url(./Assets/Fonts/Nunito-SemiBold.ttf);
}
