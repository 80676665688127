.main-table {
  display: flex;
  flex-direction: column;

  &.sortable .header th {
    cursor: pointer;
  }

  &.locations .header th:last-child,
  &.locations .body td:last-child {
    width: 125px;
    max-width: 125px;
    text-align: center;
  }

  &.selectable .header th:first-child,
  &.selectable .body td:first-child {
    width: 50px;
    max-width: 50px;
    text-align: center;
    padding: 7px 0 !important;
  }

  .header, .body {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .body .row {
    opacity: 0.9;

    &:nth-child(2n) {
      opacity: 0.8;
    }

    &.link {
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }
  }

  .row {
    border: none;
    display: flex;
  }

  .checkbox label {
    margin-top: 4px;
  }

  .cell {
    border: none;
    flex: 1;
    font-family: 'Nunito-ExtraBold';
    font-size: 12px;
    line-height: 28px;
    overflow: hidden;
    padding: 7px 35px !important;
    text-overflow: ellipsis;
    text-transform: uppercase;

    &.header-cell {
      letter-spacing: 2px;
      padding-bottom: 10px;
      padding-top: 12px;

      .checkbox label {
        &::after {
          left: 1px;
        }
      }
    }
  }
}
