.plan-container {
  .submit-btn {
    width: 134px;
    height: 37px;
    border-radius: 21.5px;
    margin-top: 10px;
    margin-bottom: 10px;
    z-index: 1;
    float: right;
  }

  .restrictions-container {
    h1 {
      color: white;
      margin-bottom: 1rem;
    }

    .restriction {
      padding: 1rem 0;
    }
  }

  .plan-form {
    max-width: 800px;
    margin: auto;

    .submit-btn {
      margin-top: 20px;
    }

    .restrictions-container {
      width: 100%;

      padding: 2rem;
      border: 2px dashed rgba(255,255,255,.4);
      border-radius: 6px;
      margin-bottom: 20px;

      .header {
        display: flex;
        justify-content: space-between;
        color: white;

        h1, h2, h3 {
          white-space: nowrap;
        }

        .hint {
          margin-left: 2rem;
        }
      }

      hr {
        margin: 2rem 0;
        opacity: 0.6;
      }

      .items-container {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        > div {
          position: relative;

          &:not(.active) {
            opacity: 0.4;
          }

          i.icon.times {
            color: white;
            font-size: 24px;
            position: absolute;
            cursor: pointer;
            top: 0px;
            left: 0px;
            z-index: 1;

            &:hover {
              opacity: .9;
            }
          }
        }

        .pointer {
          cursor: pointer;

          &:hover {
            opacity: .9;
          }
        }

        .restriction {
          color: white;
          background-color: #204695;
          padding: .75rem;
          margin: .75rem;
          border-radius: 6px;
          border: none;
          height: 40px;
          white-space: nowrap;

          &.number, &.reference {
            border-radius: 6px 0 0 6px;
            margin-right: 0;

            ~.field {
              margin: .75rem;
              margin-left: 0;

              input, .dropdown {
                border-radius: 0 6px 6px 0;
                border-color: #204695 !important;
              }

              input {
                width: 100px;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}
