@import url(https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/semantic.min.css);
body {
  margin: 0;
  /* background-color: red; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Default styles for text tags */
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
  color: #3e4042;
}

h1 {
  font-family: Nunito-Bold;
  font-size: 25px;
  line-height: 37px;
}
h2 {
  font-family: Nunito-Medium;
  font-size: 20px;
  line-height: 31px;
}
h3 {
  font-family: Nunito-SemiBold;
  font-size: 18px;
  line-height: 28px;
}
h4 {
  font-family: Nunito-SemiBold;
  font-size: 16px;
  line-height: 27px;
}
h5 {
  font-family: Nunito-Regular;
  font-size: 15px;
  line-height: 23px;
}
h6 {
  font-family: Nunito-Regular;
  font-size: 14px;
  line-height: 23px;
}
p {
  font-family: Nunito-Light;
  font-size: 14px;
  line-height: 23px;
}

@font-face {
  font-family: Nunito-Black;
  src: url(/static/media/Nunito-Black.ece7e9a3.ttf);
}
@font-face {
  font-family: Nunito-Bold;
  src: url(/static/media/Nunito-Bold.6cfc3500.ttf);
}
@font-face {
  font-family: Nunito-ExtraBold;
  src: url(/static/media/Nunito-ExtraBold.e8367d83.ttf);
}
@font-face {
  font-family: Nunito-ExtraLight;
  src: url(/static/media/Nunito-ExtraLight.e4d1c12e.ttf);
}
@font-face {
  font-family: Nunito-Light;
  src: url(/static/media/Nunito-Light.40547014.ttf);
}
@font-face {
  font-family: Nunito-Medium;
  src: url(/static/media/Nunito-Medium.26a1b6a8.ttf);
}
@font-face {
  font-family: Nunito-Regular;
  src: url(/static/media/Nunito-Regular.6198e405.ttf);
}
@font-face {
  font-family: Nunito-SemiBold;
  src: url(/static/media/Nunito-SemiBold.ce52b0b0.ttf);
}

.ButtonText_ButtonText__2oFUo:focus {
  outline: 0; }

.BENavBarProfileDropdown_Button__1OeL0:focus {
  outline: 0; }

.BENavBarProfileDropdown_DropDown__1yacf:focus {
  outline: 0; }

.BENavBarProfileDropdown_DropdownItem__17Q_V:focus {
  outline: 0; }

.BENavBarProfileDropdown_DropdownItem__17Q_V:hover {
  background-color: #f4f5f9; }

.BENavBarDropdown_Button__2viYs:focus {
  outline: 0; }

.BENavBarDropdown_DropDown__3EeRF:focus {
  outline: 0; }

.BENavBarDropdown_DropdownItem__341oX:focus {
  outline: 0; }

.BENavBarDropdown_DropdownItem__341oX:hover {
  background-color: #f4f5f9; }

.SearchContainer_SelectionContainer__etbaM:focus {
  outline: 0; }

.SearchContainer_DropDown__wMXR9:focus {
  outline: 0; }

.SearchContainer_DropdownItem__2Bug6:focus {
  outline: 0; }

.SearchContainer_DropdownItem__2Bug6:hover {
  background-color: #f4f5f9; }

.SearchContainer_searchInput__Gff8B::-webkit-input-placeholder {
  /* Chrome */
  color: white !important;
  opacity: 0.5 !important; }

.SearchContainer_searchInput__Gff8B:-ms-input-placeholder {
  /* IE 10+ */
  color: white !important;
  opacity: 0.5 !important; }

.SearchContainer_searchInput__Gff8B::-moz-placeholder {
  /* Firefox 19+ */
  color: white !important;
  opacity: 0.5 !important; }

.SearchContainer_searchInput__Gff8B:-moz-placeholder {
  /* Firefox 4 - 18 */
  color: white !important;
  opacity: 0.5 !important; }

.SearchContainer_Fade__1wAad {
  -webkit-animation: SearchContainer_fadein__3upew 1s;
  /* Safari, Chrome and Opera > 12.1 */
  /* Firefox < 16 */
  /* Internet Explorer */
  /* Opera < 12.1 */
  animation: SearchContainer_fadein__3upew 1s; }

@keyframes SearchContainer_fadein__3upew {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes SearchContainer_fadein__3upew {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

/* Internet Explorer */

/* Opera < 12.1 */

.BEDropdownSingle_Button__294kw:focus {
  outline: 0; }

.BEDropdownSingle_DropDown__3_hZq:focus {
  outline: 0; }

.BEDropdownSingle_DropdownItem__1oaiU:focus {
  outline: 0; }

.BEDropdownSingle_DropdownItem__1oaiU:hover {
  background-color: #f4f5f9; }

.ButtonCircular_Button__26R24:focus {
  outline: 0; }

.ScannerButton_ButtonContainer__RcAHu {
  height: 44px;
  margin-left: 20px;
  border-radius: 22px;
  box-shadow: 0 7px 16px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }

.ScannerButton_Button__3YOn0 {
  -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
  -webkit-transition-property: width;
  transition-property: width;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s; }
  .ScannerButton_Button__3YOn0:focus {
    outline: 0; }

.ScannerButton_Fade__1cJIP {
  -webkit-animation: ScannerButton_fadein__23D_e 2s;
  /* Safari, Chrome and Opera > 12.1 */
  /* Firefox < 16 */
  /* Internet Explorer */
  /* Opera < 12.1 */
  animation: ScannerButton_fadein__23D_e 2s; }

.ScannerButton_activeContaier__23TXp {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px; }

.ScannerButton_closeButton__15Zur {
  cursor: pointer; }

@keyframes ScannerButton_fadein__23D_e {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes ScannerButton_fadein__23D_e {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

/* Internet Explorer */

/* Opera < 12.1 */

canvas.drawing,
canvas.drawingBuffer {
  position: absolute;
  left: 0;
  top: 0; }

.ScannerCameraContainer {
  max-width: 80vw;
  width: 590px; }

.scannerCloseBtn {
  position: absolute;
  right: -8px;
  top: -8px;
  background: white;
  padding: 9px 12px 12px 12px;
  z-index: 1;
  border-radius: 50%;
  border: 1px solid #979797;
  height: 38px;
  cursor: pointer; }

.periodic-table {
  width: 100%;
  position: relative; }
  .periodic-table tr.empty {
    min-height: 24px;
    display: inline-block; }
  .periodic-table tr td.element {
    position: relative;
    cursor: pointer; }
    .periodic-table tr td.element:not(.selected) {
      opacity: 0.4; }
    .periodic-table tr td.element .number {
      position: absolute;
      top: 5px;
      left: 5px;
      font-weight: bold; }
    .periodic-table tr td.element .symbol, .periodic-table tr td.element .name {
      display: block;
      width: 100%;
      text-align: center; }
    .periodic-table tr td.element .symbol {
      font-weight: bold;
      font-size: 21px;
      margin-top: 24px; }
    .periodic-table tr td.element .name {
      font-size: 9px;
      margin-top: 3px; }

.BEDropdownIcon_Button__13VuR:focus {
  outline: 0; }

.BEDropdownIcon_DropDown__2ieYg:focus {
  outline: 0; }

.BEDropdownIcon_DropdownItem__3L66a:focus {
  outline: 0; }

.BEDropdownIcon_DropdownItem__3L66a:hover {
  background-color: #008ba4; }

.ControlsResultsWrapper_Panel__2RnNK {
  -webkit-transition-timing-function: ease-in-out;
          transition-timing-function: ease-in-out;
  -webkit-transition-property: left;
  transition-property: left;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s; }

.ControlsWrapper_Button__2oyih:focus {
  outline: 0; }

.BEDropdownMulti_Button__qW2U3:focus {
  outline: 0; }

.BEDropdownMulti_DropDown__1htq9:focus {
  outline: 0; }

.BEDropdownMulti_DropDown__1htq9::-webkit-scrollbar {
  width: 6px; }

.BEDropdownMulti_DropDown__1htq9::-webkit-scrollbar-track {
  background-color: white; }

.BEDropdownMulti_DropdownItem__bwVnf:focus {
  outline: 0; }

.filter-container {
  position: relative;
  margin-top: 15px; }
  .filter-container .dropdown-button {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 7px 16px;
    height: 44px;
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 26px;
    padding-right: 20px;
    color: #8792a5;
    cursor: pointer;
    border: none; }
    .filter-container .dropdown-button i {
      color: #00b8d9; }

.periodic-table-modal {
  width: 95vw !important; }

.ModalHeader_Button__1_wHN:focus {
  outline: 0; }

.BEToggle_Section__3ZsrK {
  -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.1s;
          transition-duration: 0.1s; }
  .BEToggle_Section__3ZsrK:focus {
    outline: 0; }

.BEToggle_ToggleSwitch__2VJJe {
  -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
  -webkit-transition-property: left;
  transition-property: left;
  -webkit-transition-duration: 0.1s;
          transition-duration: 0.1s; }

.modal-body-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0px; }

.description-container {
  font-family: 'Nunito-SemiBold';
  min-height: 50px;
  margin-bottom: 30px; }

.button-container {
  display: flex;
  justify-content: center; }

.in-progress-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.error-container {
  display: flex;
  margin: 1em;
  justify-content: center; }
  .error-container p {
    color: red;
    font-size: 13px;
    font-weight: bold; }

.loadingScreenContainer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 0;
  background-color: white;
  z-index: 102; }

.loadingScreenContainer.relativeContainer {
  position: relative;
  height: 100%;
  width: 100%; }

.RecordDetailContainer_BackButton__2ZEpm:focus {
  outline: 0; }

.GroupSelectionContainer_Button__1D0_Y:focus {
  outline: 0; }

.EditableTableRow_Button__1rYWG {
  cursor: pointer; }
  .EditableTableRow_Button__1rYWG:focus {
    outline: 0; }

.BETableRowDropdown_SelectionContainer__3kuE8:focus {
  outline: 0; }

.BETableRowDropdown_DropDown__1NFl0:focus {
  outline: 0; }

.BETableRowDropdown_DropdownItem__2AxeZ:focus {
  outline: 0; }

.BETableRowDropdown_DropdownItem__2AxeZ:hover {
  background-color: #f4f5f9; }

.main-table {
  display: flex;
  flex-direction: column; }
  .main-table.sortable .header th {
    cursor: pointer; }
  .main-table.locations .header th:last-child,
  .main-table.locations .body td:last-child {
    width: 125px;
    max-width: 125px;
    text-align: center; }
  .main-table.selectable .header th:first-child,
  .main-table.selectable .body td:first-child {
    width: 50px;
    max-width: 50px;
    text-align: center;
    padding: 7px 0 !important; }
  .main-table .header, .main-table .body {
    display: flex;
    flex: 1 1;
    flex-direction: column; }
  .main-table .body .row {
    opacity: 0.9; }
    .main-table .body .row:nth-child(2n) {
      opacity: 0.8; }
    .main-table .body .row.link {
      cursor: pointer; }
      .main-table .body .row.link:hover {
        opacity: 1; }
  .main-table .row {
    border: none;
    display: flex; }
  .main-table .checkbox label {
    margin-top: 4px; }
  .main-table .cell {
    border: none;
    flex: 1 1;
    font-family: 'Nunito-ExtraBold';
    font-size: 12px;
    line-height: 28px;
    overflow: hidden;
    padding: 7px 35px !important;
    text-overflow: ellipsis;
    text-transform: uppercase; }
    .main-table .cell.header-cell {
      letter-spacing: 2px;
      padding-bottom: 10px;
      padding-top: 12px; }
      .main-table .cell.header-cell .checkbox label::after {
        left: 1px; }

.customer-container .detail-header {
  display: flex;
  justify-content: space-between; }

.customer-container .totals-table {
  max-width: 450px; }

.customer-container .paginator-container {
  overflow: hidden;
  margin-bottom: 1em; }

.customer-container .plan-detail {
  color: white; }
  .customer-container .plan-detail span {
    font-size: 24px;
    padding-right: 1rem; }

.customer-container .list-container {
  padding: 36px 0;
  overflow: hidden; }
  .customer-container .list-container > h2 {
    color: white; }
  .customer-container .list-container div > h2 {
    color: white; }
  .customer-container .list-container.flex-2 > * {
    margin-top: 0px; }

.customer-container .formulary-link, .customer-container .submit-btn {
  width: 134px;
  height: 37px;
  border-radius: 21.5px;
  margin-top: 10px;
  margin-bottom: 10px;
  z-index: 1; }
  .customer-container .formulary-link.big, .customer-container .submit-btn.big {
    width: 150px; }

.customer-container .actions {
  display: flex;
  justify-content: flex-end; }

.customer-container .search-container .upload-container {
  float: right; }
  .customer-container .search-container .upload-container span {
    color: white; }

.customer-container .search-container .upload-btn {
  background-color: transparent;
  border: none;
  margin-top: 6px;
  cursor: pointer; }

.customer-container .search-container .hint-message {
  text-align: center;
  color: white;
  padding: 20px 0; }

.loading {
  -webkit-animation: loading 1s infinite;
  /* Safari, Chrome and Opera > 12.1 */
  /* Firefox < 16 */
  /* Internet Explorer */
  /* Opera < 12.1 */
  animation: loading 1s infinite; }

.control-center-view-container {
  padding: 36px 4%; }
  .control-center-view-container .flex, .control-center-view-container .flex-2 {
    display: flex; }
    .control-center-view-container .flex.justify-content, .control-center-view-container .flex-2.justify-content {
      justify-content: space-between;
      width: 100%; }
  .control-center-view-container .input-container {
    width: 70%;
    left: 30%;
    position: relative; }
    .control-center-view-container .input-container .search {
      border: none;
      border-radius: 22px; }
    .control-center-view-container .input-container .search-input {
      background-color: transparent;
      border: none;
      border-radius: 22px;
      height: 44px; }
      .control-center-view-container .input-container .search-input::-webkit-input-placeholder {
        /* Chrome */
        color: white !important;
        opacity: 0.5 !important; }
      .control-center-view-container .input-container .search-input:-ms-input-placeholder {
        /* IE 10+ */
        color: white !important;
        opacity: 0.5 !important; }
      .control-center-view-container .input-container .search-input::-moz-placeholder {
        /* Firefox 19+ */
        color: white !important;
        opacity: 0.5 !important; }
      .control-center-view-container .input-container .search-input:-moz-placeholder {
        /* Firefox 4 - 18 */
        color: white !important;
        opacity: 0.5 !important; }
    .control-center-view-container .input-container .search-icon {
      position: absolute;
      left: 7px;
      top: 7px; }
  .control-center-view-container .error {
    color: #FF4A4A;
    margin: 12px 0; }

.breadcrumb-container {
  display: flex;
  font-size: 24px;
  margin-bottom: 36px; }
  .breadcrumb-container > i {
    line-height: 22px; }
  .breadcrumb-container .breadcrumb-item {
    cursor: pointer;
    margin: 0 6px;
    background: none;
    border: none;
    line-height: 22px; }
    .breadcrumb-container .breadcrumb-item:first-child {
      margin-left: 0;
      margin-right: 6px; }

.dark-modal h1, .dark-modal h2, .dark-modal h3, .dark-modal h4, .dark-modal h5, .dark-modal h6, .dark-modal p {
  color: white; }

.dark-modal > * {
  border-color: rgba(255, 255, 255, 0.4) !important; }

.progress-modal:not(.complete) {
  background-color: transparent !important;
  box-shadow: none !important; }

.progress-modal > * {
  display: flex;
  align-items: center;
  justify-content: center; }

.progress-modal ol, .progress-modal p {
  color: white; }

.confirmation-modal {
  min-height: unset !important;
  color: white; }

.dark-form .datepicker .field {
  width: 100%; }

.dark-form input, .dark-form select, .dark-form .selection.dropdown {
  background-color: transparent !important;
  color: white !important;
  border-width: 2px !important;
  border-color: rgba(255, 255, 255, 0.4) !important;
  height: 40px !important; }

.dark-form i {
  color: white !important; }

.dark-form .clndr-calendars-segment {
  background-color: #0c0c27 !important;
  border-color: rgba(255, 255, 255, 0.4) !important;
  color: white; }
  .dark-form .clndr-calendars-segment .clndr-days {
    color: black; }
  .dark-form .clndr-calendars-segment .icon.button {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4) inset; }
    .dark-form .clndr-calendars-segment .icon.button:hover i {
      color: black !important; }

.dark-form .selection.dropdown .text {
  color: white !important; }

.dark-form .selection.dropdown .menu {
  background-color: #0c0c27 !important;
  border-width: 2px !important;
  border-color: rgba(255, 255, 255, 0.4) !important;
  border-top: none !important;
  margin-left: -2px;
  margin-top: 2px;
  width: calc(100% + 4px); }
  .dark-form .selection.dropdown .menu .item {
    border-top: none !important; }
    .dark-form .selection.dropdown .menu .item:hover {
      background-color: rgba(255, 255, 255, 0.2); }

.dark-form .checkbox label:before {
  background-color: transparent !important;
  border-color: rgba(255, 255, 255, 0.4) !important; }

.dark-form .checkbox.checked label:after {
  color: white !important; }

.fade {
  -webkit-animation: fadein 1s;
  /* Safari, Chrome and Opera > 12.1 */
  /* Firefox < 16 */
  /* Internet Explorer */
  /* Opera < 12.1 */
  animation: fadein 1s; }

@keyframes loading {
  0% {
    opacity: 0.5; }
  50% {
    opacity: 0.2; }
  100% {
    opacity: 0.5; } }

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes loading {
  0% {
    opacity: 0.5; }
  50% {
    opacity: 0.2; }
  100% {
    opacity: 0.5; } }

/* Internet Explorer */

/* Opera < 12.1 */

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

/* Internet Explorer */

/* Opera < 12.1 */

.admin-configuration-container .config-form-container {
  display: flex;
  width: 50%;
  margin: 1em 0; }
  .admin-configuration-container .config-form-container form {
    width: 100%; }

.admin-configuration-container h1, .admin-configuration-container h2 {
  color: white; }

.admin-configuration-container .error-container {
  margin: 1.5em; }

.plan-container .submit-btn {
  width: 134px;
  height: 37px;
  border-radius: 21.5px;
  margin-top: 10px;
  margin-bottom: 10px;
  z-index: 1;
  float: right; }

.plan-container .restrictions-container h1 {
  color: white;
  margin-bottom: 1rem; }

.plan-container .restrictions-container .restriction {
  padding: 1rem 0; }

.plan-container .plan-form {
  max-width: 800px;
  margin: auto; }
  .plan-container .plan-form .submit-btn {
    margin-top: 20px; }
  .plan-container .plan-form .restrictions-container {
    width: 100%;
    padding: 2rem;
    border: 2px dashed rgba(255, 255, 255, 0.4);
    border-radius: 6px;
    margin-bottom: 20px; }
    .plan-container .plan-form .restrictions-container .header {
      display: flex;
      justify-content: space-between;
      color: white; }
      .plan-container .plan-form .restrictions-container .header h1, .plan-container .plan-form .restrictions-container .header h2, .plan-container .plan-form .restrictions-container .header h3 {
        white-space: nowrap; }
      .plan-container .plan-form .restrictions-container .header .hint {
        margin-left: 2rem; }
    .plan-container .plan-form .restrictions-container hr {
      margin: 2rem 0;
      opacity: 0.6; }
    .plan-container .plan-form .restrictions-container .items-container {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap; }
      .plan-container .plan-form .restrictions-container .items-container > div {
        position: relative; }
        .plan-container .plan-form .restrictions-container .items-container > div:not(.active) {
          opacity: 0.4; }
        .plan-container .plan-form .restrictions-container .items-container > div i.icon.times {
          color: white;
          font-size: 24px;
          position: absolute;
          cursor: pointer;
          top: 0px;
          left: 0px;
          z-index: 1; }
          .plan-container .plan-form .restrictions-container .items-container > div i.icon.times:hover {
            opacity: .9; }
      .plan-container .plan-form .restrictions-container .items-container .pointer {
        cursor: pointer; }
        .plan-container .plan-form .restrictions-container .items-container .pointer:hover {
          opacity: .9; }
      .plan-container .plan-form .restrictions-container .items-container .restriction {
        color: white;
        background-color: #204695;
        padding: .75rem;
        margin: .75rem;
        border-radius: 6px;
        border: none;
        height: 40px;
        white-space: nowrap; }
        .plan-container .plan-form .restrictions-container .items-container .restriction.number, .plan-container .plan-form .restrictions-container .items-container .restriction.reference {
          border-radius: 6px 0 0 6px;
          margin-right: 0; }
          .plan-container .plan-form .restrictions-container .items-container .restriction.number ~ .field, .plan-container .plan-form .restrictions-container .items-container .restriction.reference ~ .field {
            margin: .75rem;
            margin-left: 0; }
            .plan-container .plan-form .restrictions-container .items-container .restriction.number ~ .field input, .plan-container .plan-form .restrictions-container .items-container .restriction.number ~ .field .dropdown, .plan-container .plan-form .restrictions-container .items-container .restriction.reference ~ .field input, .plan-container .plan-form .restrictions-container .items-container .restriction.reference ~ .field .dropdown {
              border-radius: 0 6px 6px 0;
              border-color: #204695 !important; }
            .plan-container .plan-form .restrictions-container .items-container .restriction.number ~ .field input, .plan-container .plan-form .restrictions-container .items-container .restriction.reference ~ .field input {
              width: 100px;
              height: 100%; }

.users-container .list-container {
  padding: 36px 0;
  overflow: hidden; }

.users-container .user-detail {
  color: white; }

.users-container .actions {
  display: flex;
  justify-content: flex-end; }

.users-container .submit-btn {
  width: 134px;
  height: 37px;
  border-radius: 21.5px;
  margin-left: 10px;
  margin-bottom: 10px;
  z-index: 1; }
  .users-container .submit-btn.large {
    width: auto; }
  .users-container .submit-btn:disabled {
    cursor: not-allowed !important; }

.users-container .search-container {
  display: grid;
  grid-template-columns: 250px auto;
  padding: 12px; }
  .users-container .search-container.active {
    background-color: rgba(255, 255, 255, 0.1); }
  .users-container .search-container label {
    color: white;
    font-weight: bold; }
    .users-container .search-container label + div {
      margin-top: 3px; }
  .users-container .search-container .search-bar {
    padding: 20px 0px; }
  .users-container .search-container .upload-container {
    float: right; }
    .users-container .search-container .upload-container span {
      color: white; }
  .users-container .search-container .upload-btn {
    background-color: transparent;
    border: none;
    margin-top: 6px;
    cursor: pointer; }
  .users-container .search-container .hint-message {
    text-align: center;
    color: white;
    padding: 20px 0; }

.ui.input {
  width: 100%; }

.ui.input > input {
  border-radius: 20px;
  height: 36px; }
  .ui.input > input:focus {
    border-color: #204695; }

.ui.labeled.input > .label:not(.corner) {
  height: 36px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px; }

.ui[class*='left icon'].input > input {
  padding-left: 49px !important;
  padding-right: 1em !important; }

.ui.primary.button {
  background-color: #204695;
  font-family: Nunito-SemiBold;
  font-size: 14px;
  border-radius: 20px;
  height: 36px;
  min-width: 140px; }
  .ui.primary.button:hover {
    background-color: #204695; }
  .ui.primary.button:focus {
    background-color: #204695;
    color: white;
    border: none; }

.ui.secondary.button {
  background-color: white;
  color: #204695;
  font-family: Nunito-SemiBold;
  font-size: 14px;
  border-radius: 20px;
  height: 36px;
  min-width: 140px;
  border: 1px solid #204695; }
  .ui.secondary.button:hover {
    background-color: white;
    border: 1px solid #204695;
    color: #204695; }
  .ui.secondary.button:focus {
    background-color: white;
    border: 1px solid #204695;
    color: #204695; }

.ui.basic.striped.table tbody tr:nth-child(2n) {
  background-color: #ffffff !important; }
  .ui.basic.striped.table tbody tr:nth-child(2n):hover {
    background-color: #ebecef !important; }

.ui.basic.striped.table tbody tr:nth-child(2n - 1) {
  background-color: #f4f5f9 !important; }
  .ui.basic.striped.table tbody tr:nth-child(2n - 1):hover {
    background-color: #ebecef !important; }

/** Button **/
@-webkit-keyframes buttonLoadingSpinner {
  from {
    -webkit-transform: rotate(0turn);
            transform: rotate(0turn); }
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn); } }
@keyframes buttonLoadingSpinner {
  from {
    -webkit-transform: rotate(0turn);
            transform: rotate(0turn); }
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn); } }

.button--loading {
  position: relative; }
  .button--loading .button__text {
    visibility: hidden;
    opacity: 0; }
  .button--loading::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: #ffffff;
    border-radius: 50%;
    -webkit-animation: buttonLoadingSpinner 1s ease infinite;
            animation: buttonLoadingSpinner 1s ease infinite; }

