// @import 'colors.scss';
.SelectionContainer {
  &:focus {
    outline: 0;
  }
}

.DropDown {
  &:focus {
    outline: 0;
  }
}

.DropdownItem {
  &:focus {
    outline: 0;
  }
  &:hover {
    background-color: #f4f5f9;
  }
}

.searchInput {
  // background-color: blue !important;
  &::-webkit-input-placeholder {
    /* Chrome */
    color: white !important;
    opacity: 0.5 !important;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: white !important;
    opacity: 0.5 !important;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: white !important;
    opacity: 0.5 !important;
  }
  &:-moz-placeholder {
    /* Firefox 4 - 18 */
    color: white !important;
    opacity: 0.5 !important;
  }
}

.Fade {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
