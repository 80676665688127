canvas.drawing,
canvas.drawingBuffer {
  position: absolute;
  left: 0;
  top: 0;
}
.ScannerCameraContainer {
  max-width: 80vw;
  width: 590px;
}
.scannerCloseBtn {
  position: absolute;
  right: -8px;
  top: -8px;
  background: white;
  padding: 9px 12px 12px 12px;
  z-index: 1;
  border-radius: 50%;
  border: 1px solid rgb(151,151, 151);
  height: 38px;
  cursor: pointer
}